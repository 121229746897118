import generalConfig from '@/config/general';
import universalDesignSystem from '@/config/colorPallets/universalDesignSystem'; // Sync with src/styles/settings/globals.scss

export const remBase = 16;
export default {
  remBase: 16,
  // Pixel default, can be replaced client-side
  fontFamily: `'Manrope', sans-serif`,
  widgetMaxWidth: 1100,
  colors: {
    white: universalDesignSystem.white,
    gray10: universalDesignSystem.gray10,
    gray20: universalDesignSystem.gray20,
    gray30: universalDesignSystem.gray30,
    gray60: universalDesignSystem.gray60,
    gray90: universalDesignSystem.gray90,
    gray100: universalDesignSystem.gray100,
    redPrimary: universalDesignSystem.redPrimary
  },
  globals: {
    xsmPadding: remCalc(5),
    smPadding: remCalc(10),
    mdPadding: remCalc(15),
    padding: remCalc(20),
    lgPadding: remCalc(25),
    xlgPadding: remCalc(30),
    xxlgPadding: remCalc(40),
    xxxlgPadding: remCalc(50),
    xxxxlgPadding: remCalc(60),
    xxxxxlgPadding: remCalc(70)
  },
  // Pixel Values
  // Sync with `/src/common/components/widget/settings.ts`
  globalPaddings: {
    xsmPadding: 5,
    smPadding: 10,
    mdPadding: 15,
    padding: 20,
    lgPadding: 25,
    xlgPadding: 30,
    xxlgPadding: 40,
    xxxlgPadding: 50,
    xxxxlgPadding: 60,
    xxxxxlgPadding: 70
  }
}; // Convert a pixel value to rem

export function remCalc(val) {
  return `${val / remBase}rem`;
} // Create a CSS calc function that
// converts a pixel value into REM's

export function _remCalc(val) {
  return `calc((${val} / var(--${generalConfig.productNameSpace}-rem-base) * 1rem))`;
} // Convert a rem value to pixels

export function remToPx(val) {
  return parseFloat(val) * remBase;
}