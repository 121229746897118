export const PRIMARY = '#004de5';
export const RED_PRIMARY = '#ff4e4e';
export const GRAY_100 = '#12131a';
export const GRAY_90 = '#252833';
export const GRAY_80 = '#3a3d4d';
export const GRAY_70 = '#515466';
export const GRAY_60 = '#696c80';
export const GRAY_50 = '#828699';
export const GRAY_40 = '#9a9eb2';
export const GRAY_30 = '#babdcc';
export const GRAY_20 = '#d8dae5';
export const GRAY_10 = '#ebecf2';
export const WHITE = '#fff';
export default {
  primary: PRIMARY,
  redPrimary: RED_PRIMARY,
  gray100: GRAY_100,
  gray90: GRAY_90,
  gray80: GRAY_80,
  gray70: GRAY_70,
  gray60: GRAY_60,
  gray50: GRAY_50,
  gray40: GRAY_40,
  gray30: GRAY_30,
  gray20: GRAY_20,
  gray10: GRAY_10,
  white: WHITE
};
export const chartColorPallet = [PRIMARY, GRAY_10, GRAY_20, GRAY_30, GRAY_40, GRAY_50, GRAY_60, GRAY_70, GRAY_80];
export const grayColorPallet = [GRAY_80, GRAY_70, GRAY_60, GRAY_50, GRAY_40, GRAY_30, GRAY_20, GRAY_10];